<template>
  <div>
    <el-row class="content-searcha">
      <el-form :inline="true" size="small">
        <el-form-item>
          <WarehouseMultSelector
            v-model="search.warehouseId"
            placeholder="请选择仓库"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="search._date"
            @change="setDate()"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchGetallotOutInList()"
            >搜索</el-button
          >
          <!-- <el-button type="primary" @click="$refs['export-dialog'].show('E06A')"
            >导出下载</el-button
          > -->
          <el-button type="primary" @click="handleExport"
            >导出下载</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row >
      <el-table stripe :data="allotOutInlist" border width="100%">
        <el-table-column label="仓库单号">
          <template slot-scope="{ row }">
            <p>{{ row.order_id }}</p>
            <el-tag>{{ row.aino }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="warehouse_from"
          label="调出仓库"
        ></el-table-column>
        <!-- <el-table-column prop="user_name" label="仓管"></el-table-column> -->
        <el-table-column prop="warehouse_to" label="调入仓库"></el-table-column>
        <el-table-column prop="updated_at" label="日期"></el-table-column>
        <el-table-column prop="mfn" label="MFN"></el-table-column>
        <el-table-column prop="product" label="品名"></el-table-column>
        <el-table-column label="成本价">
          <template slot-scope="{ row }">
            <span> {{ row.currency }} {{ row.cost }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="调拨总数"></el-table-column>
        <el-table-column prop="cost_amount" label="成本金额">
          <!-- <template slot-scope="scope">
            <span> {{ scope.row._currency }} {{ scope.row.cost_amount }} </span>
          </template> -->
        </el-table-column>
        <el-table-column prop="price" label="报关单价">
          <!-- <template slot-scope="scope">
            <span v-if="scope.row.wms_order_item_price === null">
              /
            </span>
            <span v-else>
              {{ scope.row.wms_order_item_price.currency_str }}
              {{ scope.row.wms_order_item_price.price }}
            </span>
          </template> -->
        </el-table-column>
        <el-table-column prop="amount" label="报关金额">
          <!-- <template slot-scope="scope">
            <span v-if="scope.row.wms_order_item_price === null">
              /
            </span>
            <span v-else>
              {{ scope.row.wms_order_item_price.currency_str }}
              {{
                (scope.row.qty * scope.row.wms_order_item_price.price)
                  | numFilter()
              }}
            </span>
          </template> -->
        </el-table-column>
        <el-table-column prop="inventory" label="调入剩余"></el-table-column>
        <!-- <el-table-column prop="remark" label="备注"></el-table-column> -->
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[100, 300, 500]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
    <!-- <export-dialog ref="export-dialog"></export-dialog> -->
    <xlsx-downloader ref="xlsx-downloader" type="FINANCE_ALLOT_IN_OUT" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { baseUrl } from '@/config/env'
import * as warehouseBillApi from '@/api/warehouseBill'
import WarehouseMultSelector from '@/components/finance/warehouseBills/WarehouseMultSelector'
import XlsxDownloader from '@/components/xlsx-downloader'
export default {
  components: {
    WarehouseMultSelector,
    XlsxDownloader
  },
  props: ['type'],
  data () {
    return {
      search: {
        start: '',
        end: ''
      },
      allotOutInlist: [],
      pageData: {
        current_page: 1,
        per_page: 100
      }
    }
  },
  filters: {
    numFilter (value) {
      const realVal = parseFloat(value).toFixed(2)
      return realVal
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  watch: {
    type: function () {
      this.getallotOutInList()
    }
  },
  created () {
    // this.getallotOutInList()
  },
  methods: {
    handleExport () {
      // TODO: type = E06A
      const params = {
        date_start: this.search.start,
        date_end: this.search.end,
        warehouse_id: this.search.warehouseId ? this.search.warehouseId.join(',') : null
      }
      this.$refs['xlsx-downloader'].show(params)
    },
    // 设置日期
    setDate () {
      if (this.search._date == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search._date[0]
        this.search.end = this.search._date[1]
      }
    },
    getallotOutInList () {
      const self = this
      console.log(self.type)
      const listJsonData = {
        type: self.type,
        warehouse_id: self.search.warehouseId,
        date_start: self.search.start,
        date_end: self.search.end,
        page: self.pageData.current_page,
        per_page: self.pageData.per_page
      }
      warehouseBillApi.getAllotOutInDetail(listJsonData).then(data => {
        self.allotOutInlist = data.data
        self.pageData.total = data.total
      })
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getallotOutInList()
    },
    changeSizeHandler (perPage) {
      this.pageData.per_page = perPage
      this.getallotOutInList()
    },
    downloadLink () {
      const url =
        baseUrl +
        'finance/wms/transferIO?token=' +
        localStorage.getItem('token') +
        '&type=' +
        this.type +
        '&warehouse_id=' +
        this.search.warehouseId.join(',') +
        '&start=' +
        this.search.start +
        '&end=' +
        this.search.end +
        '&page=' +
        this.pageData.current_page +
        '&per_page=-1'
      window.open(url)
    },
    searchGetallotOutInList () {
      this.pageData.current_page = 1
      this.getallotOutInList()
    }
  }
}
</script>
