<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">仓库单据</el-breadcrumb-item>
        <el-breadcrumb-item>调拨出入库明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-row">
      <el-tabs v-model="tabvalue">
        <el-tab-pane
          v-for="item in tabs"
          :label="item.val"
          :key="item.key"
          :name="item.key.toString()"
        ></el-tab-pane>
      </el-tabs>
    </el-row>
    <el-row>
      <allot-out-in-list :type="tabvalue"></allot-out-in-list>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import allotOutInList from './allot-out-in-list'
export default {
  components: {
    allotOutInList
  },
  data () {
    return {
      tabs: [],
      tabvalue: '3'
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    const FinanceWMSTransfer = this.sysConfig.FinanceWMSTransfer
    this.tabs = FinanceWMSTransfer.type
  }
}
</script>
